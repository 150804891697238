import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import quantox from "../../../../assets/images/companies/Quantox-Technology-logo.png";
import wackerNeuson from "../../../../assets/images/companies/Wacker-Neuson-logo.png";
import foodtec from "../../../../assets/images/companies/foodtec.jpg";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

export const CompanyCarousel = () => {
  return (
    <>
      <Typography align="center">...in finding amazing jobs in</Typography>
      <BlackAndWhite>
        <AliceCarousel
          mouseTracking
          items={items}
          responsive={responsive}
          controlsStrategy="responsive"
          disableDotsControls
          disableButtonsControls
        />
      </BlackAndWhite>
    </>
  );
};

const BlackAndWhite = styled.div`
  filter: grayscale(100%);
  margin: 0 auto;

  img {
    margin: auto;
    display: block;
  }
`;

const StyledImg = styled.img`
  margin: auto;
`;

const items = [
  <StyledImg src={quantox} alt="Quantox-company-img" height="70" />,
  <StyledImg src={foodtec} alt="FoodTec-company-img" height="70" />,
  <StyledImg src={wackerNeuson} alt="Wacker-Neuson-company-img" height="70" />,
];
