import React, { useState } from "react";
import {
  Divider,
  List,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { UserMenu } from "../user-menu/UserMenu";
import { LoginPopup } from "../../../../login/LoginPopup";
import { NavLink } from "react-router-dom";
import { ContentMenuMobile } from "./ContentMenuMobile";

type Anchor = "right";

export const Drawer = () => {
  const [menuState, setMenuState] = useState({ right: false });
  const [isShowLogin, setIsShowLogin] = useState(false);
  const isSmallerDevice = useMediaQuery("(max-width:1023px)");

  const theme = useTheme();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMenuState({ ...menuState, [anchor]: open });
    };

  const checkUser = (data: string) => {
    setIsShowLogin(data === "login");
  };

  const list = (anchor: Anchor) => (
    <div role="presentation">
      <StyledList>
        <SignOutInMenu>
          {<UserMenu setIsShowLogin={setIsShowLogin} />}
        </SignOutInMenu>
        <LoginPopup isShowLogin={isShowLogin} setIsShowLogin={setIsShowLogin} />
        <StyledDivider />
        <PopoverLinkInMenu to="/roadmap-and-progress" {...{ theme }}>
          Roadmap
        </PopoverLinkInMenu>
        <PopoverLinkInMenu to="/qaskills" {...{ theme }}>
          QASkills
        </PopoverLinkInMenu>
        <PopoverLinkInMenu to="/automation-infra" {...{ theme }}>
          AutomationInfra
        </PopoverLinkInMenu>

        <ContentMenuMobile />

        <PopoverLinkInMenu to="/reviews" {...{ theme }}>
          Reviews
        </PopoverLinkInMenu>

        <PopoverLinkInMenu to="/team" {...{ theme }}>
          Team
        </PopoverLinkInMenu>
        <PopoverLinkInMenu to="/products-price" {...{ theme }}>
          Purchase
        </PopoverLinkInMenu>
      </StyledList>
    </div>
  );

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconWrapper id={isSmallerDevice ? "profile-step" : ""}>
            <StyledIcon onClick={toggleDrawer(anchor, true)} theme={theme} />
          </IconWrapper>

          <SwipeableDrawer
            anchor={anchor}
            open={menuState[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};

const StyledIcon = styled(MenuIcon)`
  color: #747474;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const StyledList = styled(List)`
  padding: 30px;
`;

const StyledNavLink = styled(NavLink)`
  color: #000000;
  font-size: 1.2rem;
  margin-bottom: 1rem;

  &:hover,
  &:focus,
  &:active {
    color: #747474;
    opacity: 1;
    text-decoration: none;
    outline: none;
    color: #000000;
  }

  &.active {
    opacity: 1;
    border-bottom: 1px solid ${(props: any) => props.theme.palette.primary.main};
    color: #000000;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &:after {
    transition: all ease-in-out 0.2s;
    background: ${(props: any) => props.theme.palette.primary.main};
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

const PopoverLinkInMenu = styled(StyledNavLink)`
  color: #747474;
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 1rem;
`;

const SignOutInMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;
