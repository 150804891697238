import styled from "@emotion/styled";
import { Typography, useTheme } from "@mui/material";

interface TestimonialModel {
  name: string;
  position: string;
  testimonial: string;
  companyLogo?: any;
  count?: number;
  personPlaceholder?: string;
}

export const Testimonial = (props: TestimonialModel) => {
  const { name, position, testimonial, companyLogo, count, personPlaceholder } =
    props;
  const theme = useTheme();

  return (
    <Container theme={theme} count={count}>
      <PersonPlaceholderContainer>
        <img
          src={personPlaceholder}
          alt="Person placeholder"
          width="86"
          height="86"
        />
        <PersonPlaceholder>
          <Name variant="subtitle1">{name}</Name>
          <Position variant="subtitle2">{position}</Position>
        </PersonPlaceholder>
      </PersonPlaceholderContainer>
      <StyledHr />
      <DescriptionContainer>
        <Description variant="caption">{testimonial}</Description>
      </DescriptionContainer>
      {companyLogo && (
        <CompanyLogo>
          <img src={companyLogo} alt="Quantox-company-img" height="70" />
        </CompanyLogo>
      )}
    </Container>
  );
};

const Container = styled.div<{ count: number | undefined }>`
  width: 90%;
  background-color: #ffffff;
  border-radius: 2rem;
  @media (max-width: 1000px) {
    margin: auto;
  }
  margin: ${(props) => (props.count === 1 ? "auto" : "")};
`;

const PersonPlaceholderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;

  background-image: linear-gradient(
    -100deg,
    rgba(211, 255, 255, 0.1),
    rgba(0, 240, 208, 0.5) 60%,
    rgba(108, 227, 255, 0.8)
  );

  img {
    position: relative;
    margin: 0.5rem;
    display: flex;
    border-radius: 50%;
  }
`;

const PersonPlaceholder = styled.div`
  text-align: end;
  width: 100%;
`;

const Name = styled(Typography)`
  text-align: end;
  font-size: 2rem;
  padding-right: 1rem;
`;

const Position = styled(Typography)`
  text-align: end;
  padding-right: 1rem;
  @media (max-width: 430px) {
    font-size: 1.2rem;
  }
`;

const StyledHr = styled.hr`
  border-top: 1px solid #00efd1 !important;
  margin: 1rem 2rem;
`;

const DescriptionContainer = styled.div`
  overflow-y: auto;
  height: 350px;
  padding: 0 1rem;
`;

const Description = styled(Typography)`
  color: #747474;
`;

const CompanyLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.2rem;
  min-height: 90px;
  img {
    height: 50%;
    width: 50%;
  }
`;
